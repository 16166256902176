import config from "src/config";
import { codesNoSDK, sdkGeneric, sdkIncompatibilityErrors } from "../Constants/Constants"
import { v4 as uuidv4 } from 'uuid';

const CryptoJS = require('crypto-js');
const forge = require('node-forge');
const SECRET = config.SECRET;
const bytesLenght = 16

export const decryptDataAesGcm = context => {
    const secretKeyStringFormat = context.sessionId + context.data + context.clientId + SECRET;
    secretKeyStringFormat.replace(/[-_.]/g, "");

    const hashByKey = context.aesKey.toUpperCase().slice(0, bytesLenght);
    const buffer = Buffer.from(hashByKey, 'utf-8');
    const secretKeyString = buffer.toString('base64');

    const key = forge.util.decode64(secretKeyString);
    const iv = forge.util.decode64(context.iv);

    const encryptedBytes = forge.util.decode64(context.encryptedData);
    const decipher = forge.cipher.createDecipher('AES-GCM', key);
    decipher.start({
        iv: iv,
        tagLength: 128,
        tag: encryptedBytes.slice(-bytesLenght)
    });

    decipher.update(forge.util.createBuffer(encryptedBytes.slice(0, -bytesLenght)));
    const pass = decipher.finish();
    if(pass) {
        const resultData = (hextostring(decipher.output.toHex()))
        return JSON.parse(resultData)
    }else{
        return {error: true}
    }
}

const generateHash = text => {
    const hash = CryptoJS.SHA256(text);
    const hashHex = hash.toString(CryptoJS.enc.Hex);
    return hashHex.toUpperCase();
}

export const encryptDataAesGcm = (context, data) => {
    let secretKeyStringFormat = context.sessionId + context.clientId + SECRET;
    secretKeyStringFormat = secretKeyStringFormat.replace(/[-_.]/g, "");

    const hashByKey = generateHash(secretKeyStringFormat).toUpperCase().slice(0, bytesLenght);
    const buffer = Buffer.from(hashByKey, 'utf-8');
    const secretKeyString = buffer.toString('base64');

    const key = forge.util.decode64(secretKeyString);
    const iv = forge.random.getBytesSync(bytesLenght);

    const cipher  = forge.cipher.createCipher('AES-GCM', key);
    cipher.start({
        iv: iv
    });

    const base64data = JSON.stringify(data)
    cipher.update(forge.util.createBuffer(base64data));
    cipher.finish();
    
    const encrypted = cipher.output.getBytes();
    const tag = cipher.mode.tag.getBytes();
    
    return {
        data: forge.util.encode64(encrypted), 
        iv: forge.util.encode64(iv),
        tag: forge.util.encode64(tag)
    };
}

const hextostring = hex => {
    let str = '';
    const space = 2
    for (let i = 0; i < hex.length; i += space) {
        const v = parseInt(hex.substr(i, space), 16);
        if (v){str += String.fromCharCode(v)};
    }
    return str;
}

export const decryptDataAesGcmOwn = context => {
    const secretKeyStringFormat = context.sessionId + context.clientId + SECRET;
    secretKeyStringFormat.replace(/[-_.]/g, "");

    const hashByKey = generateHash(secretKeyStringFormat);
    const buffer = Buffer.from(hashByKey, 'utf-8');
    const secretKeyString = buffer.toString('base64');

    const key = forge.util.decode64(secretKeyString);
    const iv = forge.util.decode64(context.iv);
    const tag = forge.util.decode64(context.tag);
    const encryptedBytes = forge.util.decode64(context.encryptedData);
    const decipher = forge.cipher.createDecipher('AES-GCM', key);
    decipher.start({
        iv: iv,
        tag: tag
    });

    decipher.update(forge.util.createBuffer(encryptedBytes));
    const pass = decipher.finish();
    if(pass) {
        const resultData = (hextostring(decipher.output.toHex()))
        return JSON.parse(resultData)
    }else{
        return {error: true}
    }
}

export const getInternalCode = (errorCode, codesList) => {
    if(codesList[errorCode]){
        return codesList[errorCode]
    }else if(sdkGeneric[errorCode]){
        return sdkGeneric[errorCode]
    } else{
        return codesNoSDK.unknownError
    }
}

export const getErrorModalText = errorData => sdkIncompatibilityErrors[errorData] || sdkIncompatibilityErrors['DEFAULT'];

export const generateJWE = async (stringValue, _publicKey) => {
    const { JWK, JWE } = require('node-jose');
    const publicKeyPEM = `
    -----BEGIN PUBLIC KEY-----
    ${_publicKey}
    -----END PUBLIC KEY-----
    `;
    const publicKey = await JWK.asKey(publicKeyPEM, "pem");
    const buffer = Buffer.from(stringValue);
    return JWE.createEncrypt({ format: 'compact', fields: { alg: "RSA-OAEP-256", enc: "A256GCM" }}, publicKey)
    .update(buffer).final();
}

export const generateAESkey = context => {
    try{
        const secretKeyStringFormat = context.sessionId + context.clientId +  uuidv4() +  uuidv4();
        secretKeyStringFormat.replace(/[-_.]/g, "");
        return generateHash(secretKeyStringFormat)
    }catch(e){
        return null
    }
}

export const getBodyValidateUser = async context => {
    return { data: { data: context.data, key: context.jweKey }};
}

export const getBodyActivateUser= async context => {
    const data = { key: context.jweKey, data: context.encryptedDataClient, id: context.ivClient}
    return { data: data};
}  

export const getBodyValidateTransaction= async context => {
    const transactionId = config.TEST_MODE === "true" ? context.documentNumber : context.transactionId
    const data = { transactionId: transactionId, key: context.jweKey, data: context.encryptedDataClient, id: context.ivClient }
    return { data: data};
}

export const hasCameraPermissions = async () => {
    if (!navigator.mediaDevices || typeof navigator.mediaDevices.enumerateDevices !== 'function') {
        return false;
    }

    try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        return devices.some(device => device.kind === 'videoinput' && device.deviceId);
    } catch (_error) {
        return false;
    }
}

export const requestCameraPermission = async () => {
    try {
        await navigator.mediaDevices.getUserMedia({ video: true })
        return {result: true}
    } catch (error) {
        if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
            return {result: false, denied: true}
        }
        return { 
            result: false, 
            denied: false, 
            error: error.message || 'Unknown error'
        };
    }
}