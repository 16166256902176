import React from 'react';
import { ButtonContainer } from "../../Styles/ButtonContainer/ButtonContainerStyle"
import { ButtonTemplate, ButtonEnable, ButtonDisable, ButtonReject} from "../../Styles/ButtonContainer/ButtonStyle"
import { rejectTC } from "../../Constants/Constants"

function cancel(reject, canceltType) {
  reject(canceltType)
}

const ButtonContainerTemplate = ({acept, reject, isDisable, aceptText = "Continuar", rejectText = "Rechazar", canceltType = rejectTC}) => {

  const handleReject = React.useCallback(() => cancel(reject, canceltType))
  const handleAcept = React.useCallback(() => acept())

  return (
    <ButtonContainer>
        <ButtonTemplate onClick={handleAcept} customStyle={isDisable ? ButtonDisable : ButtonEnable} disabled={isDisable}>{aceptText}</ButtonTemplate>
        <ButtonTemplate onClick={handleReject} customStyle={ButtonReject}>{rejectText}</ButtonTemplate>
    </ButtonContainer>
  );
};

export default ButtonContainerTemplate;