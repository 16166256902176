import TermsAndConditions from "../Components/TermsAndConditions/TermsAndConditions"
import Context from "../Components/Context/Context"
import Modal from "../Components/Modal/Modal";
import ChallengeScreen from "../Components/ChallengeScreen/ChallengeScreen"
import CameraScreen from "../Components/CameraScreen/CameraScreen"

export function HandlerFlow({ machine, aceptTaC, begin, cancelOperation, isShowing, setRecatpcha, requestCameraPermissions}) {
    const isMatchModal = ["TermsAndConditions", "contexFlow","challenge","CameraScreen"];
    return ( 
        <>
            {(isMatchModal.some(machine.matches)) && (
                <Modal
                    isShowing={isShowing}
                    topM="0%"
                    top="0%"
                    buttonClose={false}
                    width="650px"
                    widthM="380px"
                >
                    {(machine.matches("TermsAndConditions")) && (<TermsAndConditions acept={aceptTaC} cancel={cancelOperation}/>)}
                    {(machine.matches("contexFlow")) && (<Context acept={begin} cancel={cancelOperation}/>)}
                    {(machine.matches("challenge")) && (<ChallengeScreen onRecaptchaSubmit={setRecatpcha}/>)}
                    {(machine.matches("CameraScreen")) && (<CameraScreen acept={requestCameraPermissions} cancel={cancelOperation} cameraDenied={machine.context.cameraDenied}/>)}
                </Modal>
            )}
        </>
    )
    
}