import styled from "styled-components";
import closeIcon from "../../Images/closeIcon.svg"

export const CloseButton = styled.div` 
  position: absolute; 
  width: 60px;
  height: 60px;
  top: 5px; 
  right: 5px; 
  background-image: url(${closeIcon});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  z-index: 1;
  `;