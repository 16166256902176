import { Cookies } from 'react-cookie';

export const getUrlParam = param => {
    let result = null;
    let tmp = [];
    const { hash } = document.location;
    document.location.search
      .substring(1)
      .split("&")
      .forEach(item => {
        tmp = item.split("=");
        if (param === tmp[0]) {
          if (hash !== "" && param === "redirect_uri") {
            result = decodeURIComponent(tmp[1]) + hash;
          } else {
            result = decodeURIComponent(tmp[1]);
          }
        }
      });
    return result;
  };

  export const addValueCookie = async (nameCookie, expiresTime, valueCookie) => {
    const cookies = new Cookies();
    const d = new Date();
    d.setTime(d.getTime() + expiresTime);
    cookies.set(nameCookie, valueCookie, {
      path: '/',
      expires: d,
      secure: true,
      sameSite: "strict"
    })
  }
  
  export const getValueCookie = async nameCookie => {
    const cookies = new Cookies();
    return cookies.get(nameCookie)
  }
  
  export const deleteCookie = (nameCookie) => {
      const cookies = new Cookies();
      cookies.remove(nameCookie, { path: '/' });
  };