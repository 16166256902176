import axios from "axios";
import config from "../config";
import { generateJWE,getInternalCode  } from '../Utilities/functions'
import { contentType,codesNoSDK } from "../Constants/Constants"
const URLLOGS = config.url.URLLOGS;

export const saveLogs = async (context, sourceId, origin, result, description = "") => {
  const customerTransaction = context.customerTransactionUrl || context.customerTransaction;
  try {
    const bioCode = getInternalCode(result.code, origin)
    const aditionalField = getAditionaldata(result, bioCode, description)
    const dataRequest = getSaveLogsBody(context, sourceId, bioCode.codeLog, customerTransaction, aditionalField)
    const jweRequest =  await generateJWE(dataRequest, context.bffKey)
    await makeLogsRequest(context, jweRequest)
  } catch (error) {
    const aditionalField = JSON.stringify(result);
    const dataRequest = getSaveLogsBody(context, sourceId, codesNoSDK.unknownError.codeLog, customerTransaction, aditionalField)
    const jweRequest =  await generateJWE(dataRequest, context.bffKey)
    await makeLogsRequest(context, jweRequest)
  }
}

const getAditionaldata = (result, bioCode = {}, description = '') => {
  const message = result.message ? result.message : result;
  if (bioCode.codeLog === codesNoSDK.unknownError.codeLog) {
    return result;
  } else {
    return description || message;
  }
};


const getSaveLogsBody = (context, sourceId, code, customerTransaction, aditionalData) => {
  const additionalInfoStr = JSON.stringify(aditionalData).replace(/[\\"]/g, "");
  const data = {
    documentType: context.documentType,
    documentNumber: context.documentNumber,
    sessionId: context.sessionId,
    channel: context.clientId,
    module: sourceId,
    codeLog: code,
    customerTransaction: customerTransaction,
    additionalInfo: context.isRedirect ? `customer_code:${additionalInfoStr}` : `message:${additionalInfoStr}`
  };
  const dataStr = JSON.stringify(data)
  return dataStr
}

const getHeadersAuthorize = (context) => {
  return {
    'Content-Type': contentType, 
    'Token': context.token, 
    'customer': context.clientId, 
    'session-id': context.sessionId, 
    'sso': context.cookie};
}

const makeLogsRequest = async (context, request) => {
  const headersAuthorize = getHeadersAuthorize(context)
  try {
    await axios.post(URLLOGS, {data: request}, { headers: headersAuthorize });
  } catch (error) {
    return false
  }
};
