import React from "react";
import ButtonCloseTemplate from "../ButtonClose/ButtonCloseTemplate"
import ButtonContainerTemplate from "../ButtonContainer/ButtonContainerTemplate"
import DynamicScrollbar from '../DynamicScrollbar/DynamicScrollbar';
import { Container, Title, Conditions, DescriptionText, ImageCS, ButtonAcept } from "../../Styles/CameraScreen/CameraScreenStyle"
import { cancelOperation } from "../../Constants/Constants"

function CameraScreen({cancel, acept, cameraDenied}) {
  const isCameraDenied = cameraDenied;
  const title = isCameraDenied
    ? "Activa nuevamente los permisos de tu cámara"
    : "Activa los permisos de tu cámara";
  const descriptiontext1 = isCameraDenied
    ? "Configura manualmente el permiso de acceso a la cámara para realizar la biometría facial."
    : "Para continuar, necesitamos que habilites el acceso a tu cámara. Esto nos permitirá capturar la foto necesaria";
  const descriptiontext2 = isCameraDenied ? "Ve a:" : "¿Quieres configurarlo manualmente? Ve a:";
  const descriptiontext3 = "Configuración > Apps > Permisos > Cámara";
  const aceptButtonText = isCameraDenied ? "Volver a intentar" : "Activar cámara";
  const rejectButtonText = "Cancelar";


  return (
    <Container>
      <ButtonCloseTemplate cancel={cancel}/>
      <DynamicScrollbar>
        <Conditions>
        <Title>{title}</Title>
          <ImageCS/>
          <DescriptionText marginBottom={"20px"}>{descriptiontext1}</DescriptionText>
          <DescriptionText>{descriptiontext2}</DescriptionText>
          <DescriptionText marginBottom={"60px"} weight={"700"}>{descriptiontext3}</DescriptionText>
          {cameraDenied ? (
            <ButtonAcept onClick={acept}>{aceptButtonText}</ButtonAcept>
          ) : (
            <ButtonContainerTemplate acept={acept} reject={cancel} aceptText={aceptButtonText} rejectText={rejectButtonText} canceltType={cancelOperation} />
          )}
        </Conditions>
      </DynamicScrollbar>
    </Container>
  );
}
export default CameraScreen;