import styled from "styled-components";
import logoCameraPermission from "../../Images/CameraPermission.svg"

const colors = {
  primary: "#FDDA24",
  secondary: "#2C2A29",
  white: "#ffffff",
  disabled: "#D9DADD"
};

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Open Sans";
  font-size: 15px;
  color: #2C2A29;
`;
export const Title = styled.div`
  display: flex;
  font-size: 28px;
  font-weight: bold;
  color: #2C2A29;
  text-align: center;
  margin-bottom: 20px;
  line-height: 28px;
  font-family: "CIBFont Sans";

  @media screen and (min-width: 100px) and (max-width: 961px){
    font-size: 24px;
  }
`;
export const Conditions = styled.div`
  width: auto;
  justify-content: center;
  margin: 0.5rem 30px;
  text-align: justify;

  @media screen and (max-width: 961px) {
    margin: 60px 23px 0px;
  }
`;
export const DescriptionText = styled.div`
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 24px;
  text-align: center;
  color: ${colors.secondary};
  width: auto;
  height: auto;
  text-align: start;
  margin-bottom: ${props => props.marginBottom};
  font-weight: ${props => props.weight};
`;
export const ImageCS = styled.div`
  background-image: url(${logoCameraPermission});
  background-repeat: no-repeat;
  background-position: center;
  width: auto;
  height: 130px;
  margin: 20px 0px;
`;

export const ButtonAcept = styled.button`
  background: ${colors.primary};
  color: #292929;
  border: 0px;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 50px;
  font-family: "CIBFont Sans";
  font-size: 17px;
  outline: none;
  margin: 1rem 0px;
  line-height: 20px;
  font-weight: 900;
  justify-self: center;

  @media screen and (min-width: 21px) and (max-width: 961px) {
    width: 90%;
  }
`;